<template>
  <div>
    <load-profile />
    <!-- Search Container Card -->
    <n-search-container
      v-if="searchFields.length"
      v-model="params"
      :active="true"
      :show-header="false"
      show-search
      :loading="loading"
      @search="search"
      @reset="reset"
    >
      <n-search-input
        ref="search"
        v-model="params"
        :fields="searchFields"
      >
        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              :ref="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                v-for="option in item.field.options"
                :key="option.value"
                :value="option.value"
              >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
        <template #typeId="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              :ref="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary"
            >
              <b-form-radio
                v-for="option in item.field.options"
                :key="option.value"
                :value="option.value"
              >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          @sort-changed="sortChanged"
        >
          <template #cell(image)="{ item }">
            <img
              v-if="item.imageUrl"
              :src="item.imageUrl"
              :alt="item.rewardName"
              height="50px"
            >
          </template>
          <template #cell(point)="{ item }">
            {{ item.point | currency }}
          </template>
          <template #cell(type)="{ item }">
            {{ $t(`field.${item.type}`) }}
          </template>
          <template #cell(status)="{ item }">
            <b-badge
              v-if="item.status === 1"
              pill
              variant="warning"
              badge-glow
            >
              {{ $t(`status.pending`) }}
            </b-badge>
            <b-badge
              v-else-if="item.status === 0"
              pill
              variant="danger"
              badge-glow
            >
              {{ $t(`status.rejected`) }}
            </b-badge>
            <b-badge
              v-else-if="item.status === 2"
              pill
              variant="success"
              badge-glow
            >
              {{ $t(`status.approved`) }}
            </b-badge>
          </template>
          <template #cell(isCash)="{ item }">
            <b-badge
              v-if="item.isCash"
              pill
              variant="primary"
            >
              {{ $t("field.cash") }}
            </b-badge>
            <b-badge
              v-else
              pill
              variant="info"
            >
              {{ $t("field.item") }}
            </b-badge>
          </template>
          <template #cell(amount)="{ item }">
            {{ item.amount | currency(item.ccy) }}
          </template>
          <template #cell(requestDate)="{ item }">
            {{ item.requestDate | formatDate }}
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="data.item.status === 1 && $can('update', 'claim-reward')"
                v-b-tooltip.hover
                :title="$t('button.approve')"
                variant="success"
                pill
                size="sm"
                @click="approveConfirm(data.item)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                v-if="data.item.status === 1 && $can('update', 'claim-reward')"
                v-b-tooltip.hover
                :title="$t('button.reject')"
                variant="danger"
                pill
                size="sm"
                @click="rejectConfirm(data.item)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCol, BFormGroup, BFormRadio, BFormRadioGroup, BRow, } from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination.vue'
import NSearchContainer from '@/components/NSearchContainer.vue'
import NSearchInput from '@/components/NSearchInput.vue'
import NTable from '@/components/NTable.vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { calculateDatesByPeriod } from '@/libs/helper'
import moment from 'moment'
import TableFields from './tableFields'
import SearchInputs from './searchInput'

const ClaimRewardHistoryRepository = Repository.get('claimRewardHistory')

export default {
  components: {
    BFormRadioGroup,
    BFormGroup,
    BFormRadio,
    BBadge,
    BCard,
    BRow,
    BCol,
    BButton,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        userId: Number(this.$route.query.userId) || null,
        startDate: this.$route.query.startDate || moment().format('YYYY-MM-DD'),
        endDate: this.$route.query.endDate || moment().format('YYYY-MM-DD'),
        period: this.$route.query.period || 'today',
        search: this.$route.query.search || '',
      },
      avatarText,
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
  },
  watch: {
    perPage() {
      this.list(1)
    },
    'params.period': function (value) {
      const dates = calculateDatesByPeriod(value)
      if (dates.length) {
        // eslint-disable-next-line prefer-destructuring
        this.params.startDate = dates[0]
        // eslint-disable-next-line prefer-destructuring
        this.params.endDate = dates[1]
      }
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params })
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData()
    },
    search(searchText) {
      this.params.page = 1
      this.params.search = searchText
      this.updateQuerySting()
      this.getData()
    },
    reset() {
      this.params.search = ''
      this.$refs.search.reset()
      this.params.startDate = moment().format('YYYY-MM-DD')
      this.params.endDate = moment().format('YYYY-MM-DD')
      this.params.period = 'today'
      this.params.page = 1
      this.updateQuerySting()
      this.getData()
    },
    getData() {
      this.loading = true
      const params = {
        ...this.params,
      }
      ClaimRewardHistoryRepository.index(params).then(response => {
        const { data } = response.data
        this.items = [...data.list]
        this.total = data.total
        this.loading = false
      })
        .catch(() => {
          this.loading = false
        })
    },
    approveConfirm(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t('alert.unableToRevertAfterSave'), {
          title: this.$t('alert.areYouSure'),
          okVariant: 'success',
          size: 'sm',
          buttonSize: 'md',
          okTitle: this.$t('button.approve'),
          cancelTitle: this.$t('button.cancel'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.approveReject(item, 2)
          }
        })
    },
    rejectConfirm(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t('alert.unableToRevertAfterSave'), {
          title: this.$t('alert.areYouSure'),
          okVariant: 'danger',
          size: 'sm',
          buttonSize: 'md',
          okTitle: this.$t('button.reject'),
          cancelTitle: this.$t('button.cancel'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.approveReject(item, 0)
          }
        })
    },
    approveReject(item, status) {
      // Status 2: Approve; 0: Reject
      const data = {
        id: item.id,
        status,
      }
      ClaimRewardHistoryRepository.update(data)
        .then(() => {
          this.getData()
        })
        .then(() => {
          this.loading = false
        })
    },
  },
  setup() {
    const fields = TableFields
    const searchFields = SearchInputs
    return {
      fields,
      searchFields,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
