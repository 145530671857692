export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'username',
    label: 'field.username',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'name',
    label: 'field.name',
  },
  {
    key: 'phone',
    label: 'field.phoneNumber',
  },
  {
    key: 'address',
    label: 'field.address',
  },
  {
    key: 'rewardName',
    label: 'field.reward',
  },
  {
    key: 'image',
    label: 'field.image',
  },
  {
    key: 'code',
    label: 'field.code',
  },
  {
    key: 'type',
    label: 'field.type',
  },
  {
    key: 'point',
    label: 'field.point',
  },
  {
    key: 'isCash',
    label: 'field.exchangeType',
  },
  {
    key: 'amount',
    label: 'field.cash',
  },
  {
    key: 'requestDate',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
  },
  {
    key: 'actionBy',
    label: 'field.verifier',
  },
  {
    key: 'status',
    sortable: false,
    sortField: '',
    label: 'field.status',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
]
